import React, { useState } from 'react'
import { FormLayout } from 'styles/FormLayout'
import { Label } from 'styles/PageLayout'
import {
  BarcodeAction,
  ClosedActionType,
  Config,
  isSonderpfandAction,
  RefundGroupActionType,
  RejectActionType,
  SonderpfandAction,
  SuperGroupActionType,
} from 'domain/Assortment'
import { SelectAction, SelectActionWithValue } from '../CommonComponents'

export type DisabledBarcodeActions = {
  closedActionDisabled: boolean
  rejectActionDisabled: boolean
  refundGroupActionDisabled: boolean
  superGroupActionDisabled: boolean
}

export type BarcodeActionSnapshot = {
  closedAction?: BarcodeAction | SonderpfandAction
  rejectAction?: BarcodeAction | SonderpfandAction
  refundGroupAction?: BarcodeAction | SonderpfandAction
  superGroupAction?: BarcodeAction | SonderpfandAction
}

type Props = {
  config: Config
  id: string
  snapshot: BarcodeActionSnapshot
  disabled: DisabledBarcodeActions
  sonderpfandAction?: SonderpfandAction
  onApply: (id: string, snapshot: BarcodeActionSnapshot) => void
}

export const BarcodeActionDialog = ({ config, id, snapshot, disabled, sonderpfandAction, onApply }: Props) => {
  const [closedAction, setCloseAction] = useState(snapshot.closedAction)
  const [rejectAction, setRejectAction] = useState(snapshot.rejectAction)
  const [refundGroupAction, setRefundGroupAction] = useState(snapshot.refundGroupAction)
  const [superGroupAction, setSuperGroupAction] = useState(snapshot.superGroupAction)

  const refundGroupMaxValue = 39
  const superGroupMaxValue = 59

  return (
    <div className="card">
      <FormLayout.Content>
        <h3>Active actions</h3>
        <SelectAction
          config={config}
          label={'CLOSE action:'}
          type={closedAction?.type}
          disabled={disabled.closedActionDisabled}
          allowOverride={closedAction?.allowOverride}
          options={[ClosedActionType.SET, ClosedActionType.CLEAR]}
          onTypeChanged={(value) => setCloseAction({ ...closedAction, type: value as ClosedActionType })}
          onAllowOverrideChanged={(allow) => {
            if (closedAction) {
              setCloseAction({ ...closedAction, allowOverride: allow })
            }
          }}
        />
        <SelectAction
          config={config}
          label={'REJECT action:'}
          type={rejectAction?.type}
          disabled={disabled.rejectActionDisabled}
          allowOverride={rejectAction?.allowOverride}
          options={[RejectActionType.SET]}
          onTypeChanged={(value) => setRejectAction({ ...rejectAction, type: value as RejectActionType })}
          onAllowOverrideChanged={(allow) => {
            if (rejectAction) {
              setRejectAction({ ...rejectAction, allowOverride: allow })
            }
          }}
        >
          {isSonderpfandAction(sonderpfandAction?.type) && <Label>Remove Sonderpfand action to enable</Label>}
        </SelectAction>
        <SelectActionWithValue
          config={config}
          label={'REFUND GROUP action:'}
          type={refundGroupAction?.type}
          disabled={disabled.refundGroupActionDisabled}
          value={refundGroupAction?.value}
          allowOverride={refundGroupAction?.allowOverride}
          options={[RefundGroupActionType.SET]}
          onTypeChanged={(value) =>
            setRefundGroupAction({ ...refundGroupAction, type: value as RefundGroupActionType })
          }
          onAllowOverrideChanged={(allow) => {
            if (refundGroupAction) {
              setRefundGroupAction({ ...refundGroupAction, allowOverride: allow })
            }
          }}
          onValueChanged={(value: string) => {
            if (refundGroupAction) {
              setRefundGroupAction({ ...refundGroupAction, value: value })
            }
          }}
          maxValue={refundGroupMaxValue}
        />
        <SelectActionWithValue
          config={config}
          label={'SUPER GROUP action:'}
          type={superGroupAction?.type}
          disabled={disabled.superGroupActionDisabled}
          value={superGroupAction?.value}
          allowOverride={superGroupAction?.allowOverride}
          options={[SuperGroupActionType.SET]}
          onTypeChanged={(value) => setSuperGroupAction({ ...superGroupAction, type: value as SuperGroupActionType })}
          onAllowOverrideChanged={(allow) => {
            if (superGroupAction) {
              setSuperGroupAction({ ...superGroupAction, allowOverride: allow })
            }
          }}
          onValueChanged={(value: string) =>
            setSuperGroupAction({
              type: superGroupAction?.type ?? SuperGroupActionType.SET,
              value: value,
            })
          }
          maxValue={superGroupMaxValue}
        />

        <FormLayout.FooterContainer>
          <FormLayout.Footer>
            <button
              className="btn btn-primary-dark py-sm"
              onClick={() => {
                onApply(id, {
                  closedAction: closedAction,
                  rejectAction: rejectAction,
                  refundGroupAction: refundGroupAction,
                  superGroupAction: superGroupAction,
                })
              }}
            >
              <span>Save</span>
            </button>
          </FormLayout.Footer>
        </FormLayout.FooterContainer>
      </FormLayout.Content>
    </div>
  )
}
