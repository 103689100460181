import React, { useState } from 'react'
import {
  BarcodeAction,
  BarcodeValue,
  ClosedActionType,
  Config,
  isCloseAction,
  isInstallation,
  isRefundGroupAction,
  isRejectAction,
  isSonderpfandAction,
  isSuperGroupAction,
  RejectActionType,
  removeActionTypeFromId,
  saveActions,
  SonderpfandAction,
  SonderpfandRejectActionType,
  stagedIfChanged,
  withoutUndefinedActions,
} from 'domain/Assortment'
import { Dialog } from '../../Dialog'
import { StagedModifications } from '../StagedModifications'
import { BarcodeActionDialog, BarcodeActionSnapshot, DisabledBarcodeActions } from './BarcodeActionDialog'
import { Action, EditableActions, FinalStatus, StagedAction } from '../CommonComponents'
import { BarcodeAndSonderpfand, BarcodeFile, BarcodeLine, Modification, SonderpfandGroup } from 'domain/DTO'
import { SonderpfandActionDialog, SonderpfandActionSnapshot } from './SonderpfandActionDialog'
import { SortableHeader, useTableSorting } from '../TableColumnSort'
import { paginationOptions } from '../AssortmentDatabase'
import { getPageData, PagedTableControls } from '@tomra/react-table'
import { logError } from '../../../lib/logError'

const OpenValue = ({ open }: { open: boolean }) => {
  const text = open ? 'Open' : 'Closed'
  return <div>{text}</div>
}

const AcceptedValue = ({ accepted }: { accepted: boolean }) => {
  const text = accepted ? 'Accepted' : 'Rejected'
  return <div>{text}</div>
}

type ActionMap = {
  [key: string]: BarcodeAction | SonderpfandAction
}

type BarcodeToSonderpfandGroupMap = {
  [key: string]: SonderpfandGroup
}

type SonderpfandGroupMap = {
  [key: string]: SonderpfandGroup
}

type SonderpfandDialogState = {
  show: boolean
  barcodeId?: string
  snapshot: SonderpfandActionSnapshot
}

type DialogState = {
  show: boolean
  barcodeId?: string
  sonderpfandAction?: SonderpfandAction
  snapshot: BarcodeActionSnapshot
  disabled: DisabledBarcodeActions
}

type Props = {
  config: Config
  database: BarcodeAndSonderpfand
  commonModifications: Modification[]
  installationModifications: Modification[]
  modificationUrl: string
  refreshModifications(): void
}

export const BarcodeTable = ({
  config,
  database,
  commonModifications,
  installationModifications,
  modificationUrl,
  refreshModifications,
}: Props) => {
  const [dialogState, setDialogState] = useState({ show: false } as DialogState)
  const [sonderpfandDialogState, setSonderpfandDialogState] = useState({ show: false } as SonderpfandDialogState)
  const [currentPage, setCurrentPage] = useState(1)
  const [filter, setFilter] = useState('')
  const [recordsPrPage, setRecordsPrPage] = useState(100)
  const [staged, setStaged] = useState<{ [key: string]: BarcodeAction | SonderpfandAction | undefined }>({})

  const barcodeToSonderpfandGroup = createBarcodeToSonderpfandGroupMap(database.sonderpfandGroups || [])
  const sonderpfandGroups = createSonderpfandGroupMap(database.sonderpfandGroups || [])

  const common = createBarcodeActions(commonModifications)
  const override = isInstallation(config) ? createBarcodeActions(installationModifications) : null
  const editableActions = isInstallation(config) && override != null ? override : common
  const numberOfStaged = countAffectedRows(staged, sonderpfandGroups)

  const sorting = useTableSorting<BarcodeValue>(
    {
      Barcode: (bc) => bc.code,
      Name: (bc) => bc.comment,
      Sonderpfand: (bc) => barcodeToSonderpfandGroup[bc.code]?.name ?? '',
      CommonConfig: (cg) => {
        const id = cg.code
        return (
          [
            closeActionId(id),
            rejectActionId(id),
            sonderpfandActionId(barcodeIdToSonderpfandGroupId(id, barcodeToSonderpfandGroup) ?? ''),
            refundGroupActionId(id),
            superGroupActionId(id),
          ]
            .map((id) => common[id] ?? staged[id])
            .filter((action) => !!action)[0]
            ?.type.toString() ?? ''
        )
      },
      Override: (cg) => {
        const id = cg.code
        return (
          [
            closeActionId(id),
            rejectActionId(id),
            sonderpfandActionId(barcodeIdToSonderpfandGroupId(id, barcodeToSonderpfandGroup) ?? ''),
            refundGroupActionId(id),
            superGroupActionId(id),
          ]
            .map((id) => override?.[id] ?? staged[id])
            .filter((action) => !!action)[0]
            ?.type.toString() ?? ''
        )
      },
      Accepted: (cg) => {
        return calculateFinalStatus(
          cg,
          common,
          override || {},
          staged,
          barcodeIdToSonderpfandGroupId(cg.code, barcodeToSonderpfandGroup) ?? ''
        ).toString()
      },
    },
    'Barcode'
  )

  const rows = sorting.sort(
    Array.from(fromDatabase(database.barcodeFile).values()).filter((bc) => {
      const searchText = `${bc.comment}-${bc.code}-${
        (barcodeToSonderpfandGroup?.[bc.code]?.name ? 'sonderpfand ' : '') +
          barcodeToSonderpfandGroup?.[bc.code]?.name ?? ''
      }`
      return searchText.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    })
  )

  return (
    <div>
      <div className="my-md pl-lg">
        <StagedModifications numberOfStaged={numberOfStaged} onClear={resetStaged} onApply={applyStaged} />
      </div>
      <div className="inline-flex my-sm pl-lg">
        <label>
          Search:
          <input
            type="text"
            className="mx-md"
            style={{ width: '70%' }} // overrides input[type="text"] {width: 100%}
            data-testid="search"
            onChange={(e) => {
              setFilter(e.target.value.toLocaleLowerCase())
              setCurrentPage(1)
            }}
          />
        </label>
        <abbr
          className="no-underline pt-sm"
          title={'You can search for barcodes, names or sonderpfand. Explicit sonderpfand: `sonderpfand voelkel`'}
        >
          {'❔'}
        </abbr>
      </div>
      <table className="table">
        <thead>
          <tr>
            <SortableHeader name="Barcode" currentSort={sorting.currentSort} />
            <SortableHeader name="Name" currentSort={sorting.currentSort} />
            {!!barcodeToSonderpfandGroup && <SortableHeader name="Sonderpfand" currentSort={sorting.currentSort} />}
            <th>Database</th>
            <SortableHeader name="CommonConfig" currentSort={sorting.currentSort} />
            {isInstallation(config) && <SortableHeader name="Override" currentSort={sorting.currentSort} />}
            <SortableHeader name="Accepted" currentSort={sorting.currentSort} />
          </tr>
        </thead>
        <tbody>
          {getPageData(rows, currentPage, recordsPrPage).map((barcode) => {
            const id = barcode.code
            return (
              <tr key={id}>
                <td>{barcode.code}</td>
                <td>{barcode.comment}</td>
                {barcodeToSonderpfandGroup && barcodeToSonderpfandGroup[id] ? (
                  <td>
                    <EditableActions onClick={() => openSonderpfandActionDialog(id)}>
                      {barcodeToSonderpfandGroup[id].name}
                    </EditableActions>
                  </td>
                ) : (
                  <td />
                )}
                <td>
                  <OpenValue open={barcode.open} />
                  <AcceptedValue accepted={barcode.accepted} />
                </td>
                {override ? (
                  <td>
                    {[
                      closeActionId(id),
                      rejectActionId(id),
                      sonderpfandActionId(barcodeIdToSonderpfandGroupId(id, barcodeToSonderpfandGroup) ?? ''),
                      refundGroupActionId(id),
                      superGroupActionId(id),
                    ]
                      .filter((id) => common[id])
                      .map((id) => (
                        <Action key={id} action={common[id]} />
                      ))}
                  </td>
                ) : null}
                <td>
                  <EditableActions onClick={() => openBarcodeActionDialog(id)}>
                    {[
                      closeActionId(id),
                      rejectActionId(id),
                      sonderpfandActionId(barcodeIdToSonderpfandGroupId(id, barcodeToSonderpfandGroup) ?? ''),
                      refundGroupActionId(id),
                      superGroupActionId(id),
                    ]
                      .filter((id) => editableActions[id] || staged[id])
                      .map((id) => (
                        <StagedAction key={id} original={editableActions[id]} staged={staged[id]} />
                      ))}
                  </EditableActions>
                </td>
                <td>
                  <FinalStatus
                    ok={calculateFinalStatus(
                      barcode,
                      common,
                      override ?? {},
                      staged,
                      barcodeIdToSonderpfandGroupId(id, barcodeToSonderpfandGroup) ?? ''
                    )}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <PagedTableControls
        currentPage={currentPage}
        numOfItems={rows.length}
        recordsPerPage={recordsPrPage}
        onPageUpdate={setCurrentPage}
        recordsPerPageOptions={paginationOptions}
        onRecordsPerPageChange={(recordsPerPage: number) => {
          setRecordsPrPage(recordsPerPage)
          setCurrentPage(1)
        }}
      />

      {dialogState.show && dialogState.barcodeId ? (
        <Dialog onClose={() => setDialogState({ ...dialogState, show: false })} shouldCloseOnOverlayClick={false}>
          <BarcodeActionDialog
            config={config}
            id={dialogState.barcodeId}
            snapshot={dialogState.snapshot}
            disabled={dialogState.disabled}
            sonderpfandAction={dialogState.sonderpfandAction}
            onApply={saveToStaged}
          />
        </Dialog>
      ) : null}

      {barcodeToSonderpfandGroup && sonderpfandDialogState.show && sonderpfandDialogState.barcodeId ? (
        <Dialog
          onClose={() => setSonderpfandDialogState({ show: false, snapshot: {} })}
          shouldCloseOnOverlayClick={false}
        >
          <SonderpfandActionDialog
            id={sonderpfandDialogState.barcodeId}
            numberOfRowsWithReject={findRowsWithRejectAction(
              barcodeToSonderpfandGroup[sonderpfandDialogState.barcodeId],
              editableActions,
              staged
            )}
            group={barcodeToSonderpfandGroup[sonderpfandDialogState.barcodeId]}
            snapshot={sonderpfandDialogState.snapshot}
            onApply={saveSonderpfandToStaged}
          />
        </Dialog>
      ) : null}
    </div>
  )

  function saveToStaged(id: string, snapshot: BarcodeActionSnapshot) {
    setStaged({
      ...staged,
      [closeActionId(id)]: stagedIfChanged(snapshot.closedAction, editableActions[closeActionId(id)]),
      [rejectActionId(id)]: stagedIfChanged(snapshot.rejectAction, editableActions[rejectActionId(id)]),
      [refundGroupActionId(id)]: stagedIfChanged(snapshot.refundGroupAction, editableActions[refundGroupActionId(id)]),
      [superGroupActionId(id)]: stagedIfChanged(snapshot.superGroupAction, editableActions[superGroupActionId(id)]),
    })
    setDialogState({ ...dialogState, show: false })
  }

  function openBarcodeActionDialog(id: string) {
    const sonderpfandAction = getCurrentSonderpfandAction(id)
    setDialogState({
      show: true,
      barcodeId: id,
      sonderpfandAction: sonderpfandAction,
      snapshot: {
        closedAction: staged[closeActionId(id)] ?? editableActions[closeActionId(id)],
        rejectAction: staged[rejectActionId(id)] ?? editableActions[rejectActionId(id)],
        refundGroupAction: staged[refundGroupActionId(id)] ?? editableActions[refundGroupActionId(id)],
        superGroupAction: staged[superGroupActionId(id)] ?? editableActions[superGroupActionId(id)],
      },
      disabled: {
        closedActionDisabled:
          isInstallation(config) &&
          typeof common[closeActionId(id)]?.allowOverride !== 'undefined' &&
          !common[closeActionId(id)]?.allowOverride,
        rejectActionDisabled:
          isSonderpfandAction(sonderpfandAction?.type) ||
          (isInstallation(config) &&
            typeof common[rejectActionId(id)]?.allowOverride !== 'undefined' &&
            !common[rejectActionId(id)]?.allowOverride),
        refundGroupActionDisabled:
          isInstallation(config) &&
          typeof common[refundGroupActionId(id)]?.allowOverride !== 'undefined' &&
          !common[refundGroupActionId(id)]?.allowOverride,
        superGroupActionDisabled:
          isInstallation(config) &&
          typeof common[superGroupActionId(id)]?.allowOverride !== 'undefined' &&
          !common[superGroupActionId(id)]?.allowOverride,
      },
    })
  }

  function getCurrentSonderpfandAction(id: string): SonderpfandAction | undefined {
    return (
      getSonderpfandActionForBarcodeId(staged, barcodeToSonderpfandGroup, id) ||
      getSonderpfandActionForBarcodeId(editableActions, barcodeToSonderpfandGroup, id)
    )
  }

  function saveSonderpfandToStaged(id: string, snapshot: SonderpfandActionSnapshot) {
    if (sonderpfandGroups[id]) {
      unsetExistingRejectActionsForBarcodesInGroup(sonderpfandGroups[id], editableActions, staged)
      setStaged({
        ...staged,
        [sonderpfandActionId(id)]: stagedIfChanged(snapshot.action, editableActions[sonderpfandActionId(id)]),
      })
    }
    setSonderpfandDialogState({ ...sonderpfandDialogState, show: false })
  }

  function openSonderpfandActionDialog(barcodeId: string) {
    const sonderpfandGroup = barcodeToSonderpfandGroup ? barcodeToSonderpfandGroup[barcodeId] : null
    if (sonderpfandGroup) {
      setSonderpfandDialogState({
        show: true,
        barcodeId: barcodeId,
        snapshot: {
          action:
            getSonderpfandAction(staged, sonderpfandGroup.id) ??
            getSonderpfandAction(editableActions, sonderpfandGroup.id),
        },
      })
    }
  }

  function resetStaged() {
    setStaged({})
  }

  async function applyStaged() {
    try {
      await saveActions('BARCODE', modificationUrl, editableActions, staged)
      setStaged({})
      refreshModifications()
    } catch (error: any) {
      logError(error)
    }
  }
}

function calculateFinalStatus(
  original: BarcodeValue,
  common: ActionMap,
  override: ActionMap,
  staged: { [key: string]: BarcodeAction | SonderpfandAction | undefined },
  sonderpfandGroupId: string
) {
  const closeAction =
    staged[closeActionId(original.code)] ??
    override[closeActionId(original.code)] ??
    common[closeActionId(original.code)]

  const rejectAction =
    staged[rejectActionId(original.code)] ??
    override[rejectActionId(original.code)] ??
    common[rejectActionId(original.code)]

  const sonderpfandAction =
    getSonderpfandAction(staged, sonderpfandGroupId) ??
    getSonderpfandAction(override, sonderpfandGroupId) ??
    getSonderpfandAction(common, sonderpfandGroupId)

  return (
    (closeAction ? closeAction.type !== ClosedActionType.SET : original.open) &&
    (rejectAction ? rejectAction.type !== RejectActionType.SET : original.accepted) &&
    sonderpfandAction?.type !== SonderpfandRejectActionType.SET
  )
}

function createBarcodeActions(modifications: Modification[]): ActionMap {
  return modifications.reduce((actions, modification) => {
    const action = modification.actionType
    if (action) {
      if (isCloseAction(action)) {
        actions[closeActionId(modification.referenceObjectId)] = {
          type: action,
          allowOverride: modification.allowedOverride,
        }
      } else if (isRejectAction(action)) {
        actions[rejectActionId(modification.referenceObjectId)] = {
          type: action,
          allowOverride: modification.allowedOverride,
        }
      } else if (isSonderpfandAction(action)) {
        actions[sonderpfandActionId(modification.referenceObjectId)] = {
          type: action,
        }
      } else if (isRefundGroupAction(action)) {
        actions[refundGroupActionId(modification.referenceObjectId)] = {
          type: action,
          allowOverride: modification.allowedOverride,
          value: modification.actionValue,
        }
      } else if (isSuperGroupAction(action)) {
        actions[superGroupActionId(modification.referenceObjectId)] = {
          type: action,
          allowOverride: modification.allowedOverride,
          value: modification.actionValue,
        }
      }
    }
    return actions
  }, {} as ActionMap)
}

function sonderpfandActionId(id: string) {
  return id + '[SONDERPFAND]'
}

function getSonderpfandAction(map: { [key: string]: BarcodeAction | SonderpfandAction | undefined }, groupId: string) {
  const action = map[sonderpfandActionId(groupId)]
  if (action) {
    return action as SonderpfandAction
  }
}

function getSonderpfandActionForBarcodeId(
  map: { [key: string]: BarcodeAction | SonderpfandAction | undefined },
  barcodeToGroupMap: BarcodeToSonderpfandGroupMap,
  barcodeId: string
) {
  const group = barcodeToGroupMap[barcodeId]
  if (group) {
    return getSonderpfandAction(map, group.id)
  }
}

function closeActionId(id: string) {
  return id + '[CLOSE]'
}

function rejectActionId(id: string) {
  return id + '[REJECT]'
}

function refundGroupActionId(id: string) {
  return id + '[REFUND_GROUP]'
}

function superGroupActionId(id: string) {
  return id + '[SUPER_GROUP]'
}

function barcodeLineToValue(barcodeLine: BarcodeLine): BarcodeValue {
  return {
    code: barcodeLine.code,
    comment: barcodeLine.comment || '',
    open: (Number(barcodeLine.flags) & 0x1) === 0,
    accepted: Number(barcodeLine.sortCode) !== 99,
  }
}

function fromDatabase(barcodeFile: BarcodeFile): Map<string, BarcodeValue> {
  if (barcodeFile.barcodes) {
    return barcodeFile.barcodes.reduce(
      (acc, value) => acc.set(value.code, barcodeLineToValue(value)),
      new Map<string, BarcodeValue>()
    )
  }
  logError(new Error('The original assortment contained no barcode lines.'))
  return new Map<string, BarcodeValue>()
}

function createBarcodeToSonderpfandGroupMap(groups: SonderpfandGroup[]) {
  return groups.reduce((dictionary, sonderpfandGroup) => {
    sonderpfandGroup.barcodes.forEach((barcode) => (dictionary[barcode] = sonderpfandGroup))
    return dictionary
  }, {} as BarcodeToSonderpfandGroupMap)
}

function createSonderpfandGroupMap(groups: SonderpfandGroup[]) {
  return groups.reduce((dictionary, sonderpfandGroup) => {
    dictionary[sonderpfandGroup.id] = sonderpfandGroup
    return dictionary
  }, {} as SonderpfandGroupMap)
}

function barcodeIdToSonderpfandGroupId(barcodeId: string, map: BarcodeToSonderpfandGroupMap) {
  const group = map[barcodeId]
  if (group) {
    return group.id
  }
}

function findRowsWithRejectAction(
  group: SonderpfandGroup,
  existing: ActionMap,
  staged: { [key: string]: BarcodeAction | SonderpfandAction | undefined }
) {
  const merged = { ...existing, ...staged }
  return group.barcodes
    .map((barcode) => merged[rejectActionId(barcode)])
    .filter((action) => !isSonderpfandAction(action?.type))
    .filter((action) => !!action).length
}

function unsetExistingRejectActionsForBarcodesInGroup(
  group: SonderpfandGroup,
  existing: ActionMap,
  staged: { [key: string]: BarcodeAction | SonderpfandAction | undefined }
) {
  const merged = { ...existing, ...staged }
  group.barcodes
    .filter((barcode) => !!merged[rejectActionId(barcode)])
    .forEach((barcode) => (staged[rejectActionId(barcode)] = { type: 'UNSET' }))
}

function countAffectedRows(
  staged: { [key: string]: BarcodeAction | SonderpfandAction | undefined },
  sonderpfandGroups: SonderpfandGroupMap
): number {
  const affectedRows = Object.entries(withoutUndefinedActions(staged)).reduce((affectedRows, entry) => {
    const [id, action] = entry
    if (isSonderpfandAction(action?.type)) {
      const group = sonderpfandGroups[removeActionTypeFromId(id)]
      group.barcodes.forEach((barcode) => (affectedRows[barcode] = ''))
    } else {
      affectedRows[removeActionTypeFromId(id)] = ''
    }
    return affectedRows
  }, {} as { [key: string]: any })

  return Object.keys(affectedRows).length
}
