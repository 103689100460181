import { ArtikeldatenFile, ArtikeldatenGroup } from '../../../domain/DTO'
import React, { useState } from 'react'
import { SortableHeader, useTableSorting } from '../TableColumnSort'
import { getPageData, PagedTableControls } from '@tomra/react-table'

type Props = {
  database: ArtikeldatenFile
}

export const ArtikeldatenTable = ({ database }: Props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPrPage, setRecordsPrPage] = useState(100)
  const [filter, setFilter] = useState('')

  const sorting = useTableSorting<ArtikeldatenGroup>({
    articleNumber: (an) => an.articleNumber,
  })

  const rows = sorting.sort(
    Array.from(database.content).filter((adg) =>
      `${adg.articleNumber}-id`.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
    ),
  )
  return (
    <div>
      <div className="inline-flex my-sm pl-lg">
        <label>
          Search:
          <input
            type="text"
            className="mx-md"
            style={{ width: '70%' }} // overrides input[type="text"] {width: 100%}
            data-testid="search"
            onChange={(e) => {
              setFilter(e.target.value.toLocaleLowerCase())
              setCurrentPage(1)
            }}
          />
        </label>
      </div>
      <table className="table">
        <thead>
          <tr>
            <SortableHeader name="articleNumber" currentSort={sorting.currentSort} />
            <SortableHeader name="FromDate" currentSort={sorting.currentSort} />
            <SortableHeader name="ToDate" currentSort={sorting.currentSort} />
          </tr>
        </thead>
        <tbody>
          {getPageData(rows, currentPage, recordsPrPage).map((artikelDaten) => {
            const id = artikelDaten.articleNumber
            return (
              <tr key={id}>
                <td>{artikelDaten.articleNumber}</td>
                <td>{artikelDaten.validFrom}</td>
                <td>{artikelDaten.validTo}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <PagedTableControls
        currentPage={currentPage}
        numOfItems={rows.length}
        recordsPerPage={recordsPrPage}
        onPageUpdate={setCurrentPage}
        onRecordsPerPageChange={(recordsPrPage: number) => {
          setRecordsPrPage(recordsPrPage)
          setCurrentPage(1)
        }}
      />
    </div>
  )
}
